import { useForm, ValidationError } from "@formspree/react";
import { navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import TranslateComponent from "./TranslateComponent";

function ClientForm({ heading, body, companyName, languageCode }) {
	const [state, handleSubmit] = useForm("xldjrrda");

	useEffect(() => {
		if (state.succeeded) {
			navigate("/thank-you");
		}
	}, [state.succeeded]);

	// Helper function to translate text and return a string
	const t = (text) => {
		// Assuming TranslateComponent returns a string:
		return TranslateComponent({ code: languageCode, text });
	};

	return (
		<section className="py-5 py-lg-7">
			<Container>
				<Row className="justify-content-center">
					<Col md={10} lg={6}>
						<div
							style={{ borderRadius: "12px", border: "1px solid #CEE4FF" }}
							className="p-4"
						>
							<div className="text-center pb-4">
								<h1 className="text-gatsby display-4 mb-3">{t(heading)}</h1>
								<p>{t(body)}</p>
							</div>
							<Form className="contact-form text-start" onSubmit={handleSubmit}>
								{/* Name Field */}
								<Form.Group controlId="name" className="mb-4">
									<Form.Label className="">{t("Name")}:</Form.Label>
									<Form.Control
										className="special-placeholder"
										name="name"
										type="text"
										placeholder={t("Your Name:")}
										style={nameInputStyles}
									/>
									<ValidationError
										prefix="Name"
										field="name"
										errors={state.errors}
									/>
								</Form.Group>
								<Form.Group controlId="companyName" className="d-none">
									<Form.Control
										className="special-placeholder"
										name="companyName"
										type="text"
										value={companyName}
									/>
									<ValidationError
										prefix="companyName"
										field="companyName"
										errors={state.errors}
									/>
								</Form.Group>
								<Form.Group controlId="phone" className="mb-4">
									<Form.Label className="">{t("Phone")}:</Form.Label>
									<Form.Control
										className="special-placeholder"
										name="phone"
										type="tel"
										placeholder={t("Your Phone Number:")}
										style={emailInputStyles}
									/>
									<ValidationError
										prefix="phone"
										field="phone"
										errors={state.errors}
									/>
								</Form.Group>
								{/* Email Field */}
								<Form.Group controlId="email" className="mb-4">
									<Form.Label className="">{t("Email")}:</Form.Label>
									<Form.Control
										className="special-placeholder"
										name="email"
										type="email"
										placeholder={t("Your Email:")}
										style={emailInputStyles}
									/>
									<ValidationError
										prefix="Email"
										field="email"
										errors={state.errors}
									/>
								</Form.Group>
								<Form.Group controlId="location" className="mb-4">
									<Form.Label className="">{t("Location")}:</Form.Label>
									<Form.Control
										className="special-placeholder"
										name="location"
										type="text"
										placeholder={t("Your Location:")}
										style={emailInputStyles}
									/>
									<ValidationError
										prefix="location"
										field="location"
										errors={state.errors}
									/>
								</Form.Group>
								<Form.Group
									controlId="whatWouldYouLikeHelpWith"
									className="mb-4"
								>
									<Form.Label className="">{t("How Can We Help")}?</Form.Label>
									<Form.Control
										className="special-placeholder"
										name="whatWouldYouLikeHelpWith"
										type="text"
										placeholder={t("What would you like help with?")}
										style={emailInputStyles}
									/>
									<ValidationError
										prefix="whatWouldYouLikeHelpWith"
										field="whatWouldYouLikeHelpWith"
										errors={state.errors}
									/>
								</Form.Group>

								{/* Message Field */}
								<Form.Group controlId="message" className="mb-4">
									<Form.Control
										name="message"
										as="textarea"
										rows={5}
										placeholder={t("Type your message...")}
										style={textareaStyles}
									/>
									<ValidationError
										prefix="Message"
										field="message"
										errors={state.errors}
									/>
								</Form.Group>

								{/* Accept Terms */}
								<Form.Group controlId="acceptTerms" className="mb-4">
									<Form.Check
										type="checkbox"
										label={
											<span className="calibri">
												{t("I accept all the")}
												<a
													href="/website-terms-and-conditions"
													className="purple-link fw-bold mx-1"
												>
													{t("Terms & Policies")}
												</a>
												{t("of Premier Support Services")}
											</span>
										}
										required
										style={checkboxStyles}
									/>
								</Form.Group>

								{/* Submit Button */}
								<Button
									style={{ borderRadius: "0px" }}
									className="w-100 py-2"
									variant="gatsby"
									type="submit"
									disabled={state.submitting}
								>
									{t("Submit")}
								</Button>
							</Form>
							<div className="text-center pt-3">
								<StaticImage
									src="../images/icon.png"
									alt={t("Premier Support Services Logo")}
									style={{ maxWidth: "150px" }}
									quality={100}
								/>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
}

export default ClientForm;

const nameInputStyles = {
	border: "none",
	borderBottom: "1px solid #562A79",
	borderRadius: 0,
	padding: "10px",
	fontSize: "16px",
	color: "#333",
};

const emailInputStyles = {
	border: "none",
	borderBottom: "1px solid #CEE4FF",
	borderRadius: 0,
	padding: "10px",
	fontSize: "16px",
	color: "#333",
};

const textareaStyles = {
	border: "1px solid #CEE4FF",
	borderRadius: "0px",
	padding: "10px",
	fontSize: "16px",
	resize: "none",
};

const checkboxStyles = {
	marginTop: "10px",
	fontSize: "14px",
};
