import React, { useEffect, useState } from "react";
import LanguageDropdown from "../../components/LanguageDropdown";
import { Col, Container, Row } from "react-bootstrap";
import TranslateComponent from "../../components/TranslateComponent";
import HubHero from "../../components/HubHero";
import withAuth from "../../components/withAuth";
import ClientLayout from "../../components/client-Layout";
import TranslationBanner from "../../components/TranslationBanner";
import CardLayoutSection from "../../components/CardLayoutSection";
import WelcomeSection from "../../components/WelcomeSection";
import { graphql } from "gatsby";
import ClientForm from "../../components/ClientForm";
import SplashScreen from "../../components/SplashScreen";

const GetInTouchClient = ({ data }) => {
	const [clientData, setClientData] = useState(null);
	const [languageCode, setLanguageCode] = useState("en");
	const [heroData, setHeroData] = useState({
		heading: "",
		body: "",
		image: "",
	});

	const handleLanguageChange = (code) => {
		setLanguageCode(code);
	};

	const fetchData = async (endpoint) => {
		const token = localStorage.getItem("token");

		try {
			const response = await fetch("/.netlify/functions/fetchData", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ endpoint, token }),
			});

			const result = await response.json();

			if (!response.ok) {
				console.error(`Error fetching ${endpoint} data:`, result.message);
				return null;
			}

			return result;
		} catch (error) {
			console.error("Fetch error:", error);
			return null;
		}
	};

	useEffect(() => {
		const getData = async () => {
			const result = await fetchData("client?_fields=acf&acf_format=standard");

			if (result) {
				setClientData(result);
				// Set the hero data after fetching
				const heroSection = result[0]?.acf?.hero_section;
				setHeroData({
					heading: heroSection?.heading || "",
					body: heroSection?.body || "",
					image: heroSection?.image || "",
				});
			} else {
				setClientData(null);
			}
		};

		getData();
	}, []);

	// Extract data from GraphQL query
	const getInTouchNode = data.allWpClientPage.edges[0]?.node;
	const getInTouchFields = getInTouchNode?.getInTouchFields;
	const pageHeading = getInTouchFields?.heading || "";
	const pageBody = getInTouchFields?.body || "";

	return (
		<div>
			{clientData ? (
				<ClientLayout
					navLinks={clientData[0]?.acf.navbar_links}
					languageCode={languageCode}
				>
					<div>
						<TranslationBanner
							languageCode={languageCode}
							onSelectLanguage={handleLanguageChange}
						/>

						<ClientForm
							languageCode={languageCode}
							heading={pageHeading}
							body={pageBody}
							companyName={clientData[0]?.acf.company_name}
						/>
					</div>
				</ClientLayout>
			) : (
				<SplashScreen />
			)}
		</div>
	);
};

export const query = graphql`
	query {
		allWpClientPage(filter: { slug: { eq: "get-in-touch" } }) {
			edges {
				node {
					id
					getInTouchFields {
						heading
						body
					}
				}
			}
		}
	}
`;

export default withAuth(GetInTouchClient, "client");
